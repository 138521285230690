import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useModalStore = defineStore('modal-store', () => {

  const modal = ref({
      showModal: false,
      component: undefined,
      props:{},
      isBrand: false
  });
  
  function showHideModal(payload){
    modal.value = payload;
    if (process.client) {
      document.body.classList.add('modal-open');
    }
  }

  function hideModal(){
    modal.value = {
      showModal: false,
      component: undefined,
      props:{}
    };
    if (process.client) {
      document.body.classList.remove('modal-open');
    }
  }

  return { 
    modal,
    showHideModal, 
    hideModal
  };
});